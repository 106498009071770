.home__education {
    position: relative;
    height: 1.5em; /* Adjust based on your font size */
    overflow: hidden;
  }
  
  .title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease;
  }
  
  .slide-out {
    transform: translateY(-100%);
  }