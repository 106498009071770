/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 3.5rem;

  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  /*
        Purple: hsl(250, 66%, 75%)
        Blue: hsl(207, 90%, 72%)
        Pink: hsl(356, 66%, 75%)
        Teal: hsl(174, 63%, 62%)
  */
  --first-hue: 207;
  --sat: 90%;
  --lig: 72%;
  --second-hue: 219;
  --first-color: hsl(var(--first-hue), var(--sat), var(--lig));
  --first-color-alt: hsl(var(--first-hue), var(--sat), 68%); /* -4% */
  --title-color: hsl(var(--second-hue), 15%, 95%);
  --text-color: hsl(var(--second-hue), 8%, 75%);
  --text-color-light: hsl(var(--second-hue), 4%, 55%);
  --body-color: hsl(var(--second-hue), 48%, 8%);
  --container-color: hsl(var(--second-hue), 32%, 12%);

  /*========== Font and typography ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --body-font: 'Poppins', sans-serif;
  --biggest-font-size: 1.75rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: .938rem;
  --small-font-size: .813rem;
  --smaller-font-size: .75rem;
  --tiny-font-size: .625rem;

  /*========== Font weight ==========*/
  --font-medium: 500;
  --font-semibold: 600;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

/* Responsive typography */
@media screen and (min-width: 968px) {
  :root {
    --biggest-font-size: 2.5rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: .875rem;
    --smaller-font-size: .813rem;
  }
}

/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body {
  background-color: var(--body-color);
  color: var(--text-color);
  transition: .4s; /* for light mode animation */
}



h1, h2, h3 {
  color: var(--title-color);
  font-weight: var(--font-semibold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

img {
  /* max-width: 100%; */
  height: auto;
}

/*=============== THEME ===============*/
.change-theme{
  font-size: 1.5rem;
  cursor: pointer;
  transition:.4s ;
}
.change-theme:hover{
  color: var(--first-color);
}

/*========== Variables light theme ==========*/
body.light-theme{
   --title-color: hsl(var(--second-hue), 15%, 15%);
  --text-color: hsl(var(--second-hue), 8%, 35%);
  --body-color: hsl(var(--second-hue), 100%, 99%);
  --container-color: #fff;
}


/*========== 
    Color changes in some parts of 
    the website, in light theme
==========*/
.light-theme .scroll-header{
  box-shadow: 0 2px 4px hsla(0,0%,1%,.1);
}
.light-theme .nav__menu{
  background-color:hsla(var(--second-hue),32%,90%,.8) ;
}
.light-theme .section__subtitle{
  color:var(--text-color) ;
}
.light-theme .home__social-link{
  box-shadow: 0 2px 8px hsla(var(--second-hue),48%,8%,.1);
}
.light-theme .home__social::after,
.light-theme .footer__social-link{
  background-color: var(--title-color);
}
.light-theme .home__social-link,
.light-theme .home__scroll,
.light-theme .button,
.light-theme .button:hover,
.light-theme .active-work,
.light-theme .footer__title,
.light-theme .footer__link,
.light-theme .footer__copy{
  color:var(--title-color) ;
}
.light-theme .about__box{
  box-shadow: 0 2px 8px hsla(var(--second-hue),48%,8%,.1);
}
.light-theme .skills__content,.light-theme .services__card,.work__card,.light-theme .testimonial__card,.light-theme .contact__card{
  box-shadow: 0 2px 16px hsla(var(--second-hue),48%,8%,.1);
}
.light-theme::-webkit-scrollbar{
  background-color: hsl(var(--second-hue), 8%, 66%);
}
.light-theme::-webkit-scrollbar-thumb{
  background-color: hsl(var(--second-hue), 8%, 54%);
}
.light-theme::-webkit-scrollbar-thumb:hover{
  background-color: hsl(var(--second-hue), 8%, 44%);
}

/*=============== REUSABLE CSS CLASSES ===============*/
.container {
  max-width: 968px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.grid {
  display: grid;
  gap: 1.25rem;
}

.main {
  overflow: hidden;
}

.section {
  padding: 4.5rem 0 1rem;
}

.section__title, 
.section__subtitle {
  text-align: center;
}

.section__title {
  font-size: var(--h2-font-size);
  color: var(--first-color);
  margin-bottom: 2rem;
}

.section__subtitle {
  display: block;
  font-size: var(--smaller-font-size);
  color: var(--text-color-light);
}

/*=============== HEADER & NAV===============*/
.header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--body-color) !important;
  z-index: var(--z-fixed);
  transition: .4s;
}
.nav{
 height:var(--header-height) ;
 display: flex;
 justify-content: space-between;
 align-items: center;
}
.nav__logo{
  color: var(--first-color);
  font-weight: var(--font-medium);
  transition: .4s;
}
.nav__logo:hover{
  color: var(--first-color-alt);

}
.nav__menu{
  position: fixed;
  bottom: 1rem;
  background-color: hsla(var(--second-hue),32%,16%,.8);
  width: 90%;
  border-radius: 4rem;
  padding: 1rem 2.25rem;
  backdrop-filter: blur(10px);
  transition: .4s; 
}
.nav__list{
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.nav__link{
  color: var(--text-color);
  font-size: 1.25rem;
  padding: .4rem;
  display: flex;
  border-radius: 5rem;
}



/* Active link */
.active-link{
  background: linear-gradient(180deg,hsla(var(--first-hue),var(--sat),var(--lig),1),hsla(var(--first-hue),var(--sat),var(--lig),.2));
  box-shadow: 0 0 16px hsla(var(--first-hue),var(--sat),var(--lig),.4);
  color: var(--title-color);
}


/* Change background header */
.scroll-header{
  box-shadow: 0 4px  4px hsla(0,0%,4%,.3);

}

/*=============== HOME ===============*/
.home__container{
  position: relative;
  row-gap: 4.5rem;
  padding-top: 2rem;
}
.home__data{
  text-align: center;
}
.home__greeting,.home__education{
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);
}
.home__greeting{
  display: block;
  color: var(--title-color);
  margin-bottom: .25rem;
}
.home__education{
  color: var(--text-color);
  margin-bottom: 2.5rem;
}
.home__name{
  font-size: var(--biggest-font-size);
}
.home__img{
  width: 250px;
}
.home__handle{
  justify-self: center;
  width: 190px;
  height: 285px;
  background: linear-gradient(180deg,hsla(var(--first-hue),var(--sat),var(--lig),1),hsla(var(--first-hue),var(--sat),var(--lig),.2));
  border-radius: 10rem 10rem 1rem 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
}
.home__buttons{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.home__social,.home__scroll{
  position: absolute;
}
.home__social{
  bottom: 5rem;
  left: 0;
  display: grid;
  row-gap: .5rem;
}
.home__social-link{
  width: max-content;
  background-color:var(--container-color);
  color: var(--first-color);
  padding: .25rem;
  border-radius: .25rem;
  display: flex;
  font-size: 1rem;
  transition: .4s;
}
.home__social-link:hover{
  background-color: var(--first-color);
  color: #fff;

}
.home__social::after{
  content: "";
  width: 32px;
  height: 2px;
  background-color: var(--first-color);
  transform: rotate(90deg) translate(16px, 3px);
}
.home__scroll{
  color: var(--first-color);
  right: -1.5rem;
  bottom: 4rem;
  display: grid;
  row-gap: 2.25rem;
  justify-items: center;
}
.home__scroll-icon{
  font-size: 1.25rem;
}
.home__scroll-name{
  font-size: var(--smaller-font-size);
  transform: rotate(-90deg);
}
/*=============== BUTTONS ===============*/
.button{
  display: inline-block;
  background-color: var(--first-color);
  color: var(--body-color);
  padding: .75rem 1rem;
  border-radius: .5rem;
  font-weight: var(--font-medium);
  transition: .4s;
}
.button:hover{
  background-color: var(--first-color-alt);
  color: var(--body-color);
}
.button__ghost{
background-color: transparent;
border: 2px solid var(--first-color);
color: var(--first-color);
}

/*=============== ABOUT ===============*/
.about__container{
  row-gap: 2.5rem;
}
.about__img{
  width: 220px;
  border-radius: 1.5rem;
  justify-self: center;
}
.about__data{
  text-align: center;
}
.about__info{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: .5rem;
  margin-bottom: 2rem;
}
.about__box{
  background-color: var(--container-color);
  border-radius: .75rem;
  padding: .75rem .5rem;
}
.about-icon{
  font-size: 1.5rem;
  color: var(--first-color);
  margin-bottom: .5rem;
}
.about__title{
  font-size: var(--small-font-size);
}
.about__subtitle{
  font-size: var(--tiny-font-size);
}
.about__description{
  margin-bottom: 2rem;
}



/*=============== SKILLS ===============*/
.skills__container{
  row-gap: 2rem;
  padding-top: 1rem;
}
.skills__content{
  background-color: var(--container-color);
  padding: 1.5rem;
  border-radius: 1.25rem;
}
.skills__title{
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  color: var(--first-color);
  text-align: center;
  margin-bottom: 1.5rem;
}
.skills__box{
  display: flex;
  justify-content: center;
  column-gap: 2.5rem;
}
.skills__group{
  display: grid;
  align-content: flex-start;
  row-gap: 1rem;
}
.skills__data{
  display: flex;
  column-gap: .5rem;
}
.skills .bxs-badge-check{
  font-size: 1rem;
  color: var(--first-color);
}
.skills__name{
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  line-height: 18px;
}
.skills__level{
  font-size: var(--tiny-font-size);
}


/*=============== SERVICES ===============*/
.services__container{
  grid-template-columns: repeat(2,1fr);
  gap: 1.5rem;
  padding-top: 1rem;
}
.services__card{
  background-color: var(--container-color);
  padding: 3rem 1.5rem 1.5rem;
  border-radius: 1rem;
}
.services__title{
  font-size: var(--h3-font-size);
  margin-bottom: 0.5rem;
}
.services__button{
  color: var(--first-color);
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  column-gap: .25rem;
  cursor: pointer;
}
.services__button:hover .services__icon{
  transform: translateX(.25rem);
}
.services__icon{
  font-size: 1rem;
  transition: .4s;
}

/* Services modal */
.services__modal{
  position: fixed;
  inset: 0;
  background-color: hsla(var(--second-hue),28%,16%,.7);
  padding: 2rem 1rem;
  display: grid;
  place-items: center;
  visibility: hidden;
  opacity: 0;
  transition: .4s;
  z-index: var(--z-modal);
}
.services__modal-content{
  position: relative;
  background-color: var(--body-color);
  padding: 4.5rem 1.5rem 2.5rem;
  border-radius: 1.5rem;
}
.services__modal-title,.services__modal-description{
  text-align: center;
}
.services__modal-title{
  font-size: var(--h3-font-size);
  color: var(--first-color);
  margin-bottom: 1rem;
}
.services__modal-description{
  font-size: var(--small-font-size);
  margin-bottom: 2rem;
}
.services__modal-list{
  display: grid;
  row-gap: .75rem;
}
.services__modal-item{
  display: flex;
  align-items: flex-start;
  column-gap: .5rem;

}
.services__modal-icon{
  font-size: 1.5rem;
  color: var(--first-color);
}
.services__modal-info{
  font-size: var(--small-font-size);
}
.services__modal-close{
  position: absolute;
  right: 1.5rem;
  top:1.5rem;
  font-size: 1.5rem;
  color: var(--first-color);
  cursor: pointer;
}



/*Active modal*/
.active-modal{
  opacity: 1;
  visibility: visible;
}

/*=============== WORK ===============*/
.work__container{
  padding-top: 1rem;
}
.work__filters{
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: .75rem;
  margin-bottom: 2rem;
}
.work__item{
  cursor: pointer;
  color: var(--title-color);
  padding: .25rem .75rem;
  font-weight: var(--font-medium);
  border-radius: .5rem;
}

.work__card{
  background-color:var(--container-color);
  padding: 1rem;
  border-radius: 1rem;
}
.work__img{
  border-radius: 1rem;
  margin-bottom: .75rem;
  max-width: 100%;
}
.work__title{
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  margin-bottom: .25rem;
}
.work__button{
  width: max-content;
  color: var(--first-color);
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  column-gap: .25rem;
}
.work__button:hover .work-icon{
  transform: translateX(.25rem);
}
.work-icon{
  font-size: 1rem;
  transition: .4s;
}





/* Active item work*/
.active-work{
  background-color: var(--first-color);
  color: var(--body-color);
}

/*=============== TESTIMONIAL ===============*/
.testimonial__card{
  background-color: var(--container-color);
  padding: 1.25rem 1.5rem;
  border-radius: 1.5rem;
  margin-bottom: 3rem;
}
.testimonial__img{
  width: 60px;
  border-radius: 3rem;
  margin-bottom: 1rem;
}
.testimonial__name{
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  margin-bottom: .25rem;
}
.testimonial__description{
  font-size: var(--small-font-size);
}

/* Swiper class */
.swiper-pagination-bullet{
  background-color: var(--text-color-light);
}
.swiper-pagination-bullet-active{
  background-color: var(--first-color);
}

/*=============== CONTACT ===============*/
.contact__container{
  row-gap: 3rem;
  padding-bottom: 3rem;
}
.contact__title{
  text-align: center;
  font-size: var(--h3-font-size);
  margin-bottom: 1.5rem;
}
.contact__info{
  display: grid;
  gap: 1rem;
}
.contact__card{
  background-color: var(--container-color);
  padding: 1rem;
  border-radius: .75rem;
  text-align: center;
}
.contact__card-icon{
  font-size: 2rem;
  color: var(--title-color);
  margin-bottom: .25rem;
}
.contact__card-title,.contact__card-data{
  font-size: var(--small-font-size);
}
.contact__card-title{
  font-weight: var(--font-medium);
}
.contact__card-data{
  display: block;
  margin-bottom: .75rem;
}
.contact__button{
  color: var(--first-color);
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: .25rem;
}
.contact__button:hover .contact__button-icon{
  transform: translateX(.25rem);
}
.contact__button-icon{
  font-size: 1rem;
  transition: .4s;
}
.contact__form-div{
  position: relative;
  margin-bottom: 2rem;
  height: 4rem;
}
.contact__form-input{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid var(--text-color-light);
  background: none;
  color: var(--text-color);
  outline: none;
  padding: 1.5rem;
  border-radius: .75rem;
  z-index:1;
}
.contact__form-tag{
  position: absolute;
  top: -.75rem;
  left: 1.25rem;
  font-size: var(--small-font-size);
  padding: .25rem;
  background-color: var(--body-color);
  z-index: 10;
}
.contact__form-area{
  height: 11rem;
}
.contact__form-area textarea{
  resize: none;
}
/*=============== FOOTER ===============*/
.footer{
  background-color: var(--first-color);
}
.footer__container{
  padding: 2rem 0 6rem;
}
.footer__title,.footer__link{
  color: var(--body-color);
}
.footer__title{
  text-align: center;
  margin-bottom: 2rem;
}
.footer__list{
  display: flex;
  justify-content: center;
  column-gap: 1.5rem;
  margin-bottom: 2rem;
}
.footer__social{
  display: flex;
  justify-content: center;
  column-gap: 1.25rem;
}
.footer__social-link{
  background-color: var(--body-color);
  color: var(--first-color);
  padding: .25rem;
  border-radius: .25rem;
  font-size: 1rem;
  display: inline-flex;
}
.footer__copy{
  display: block;
  margin-top: 4.5rem;
  color: var(--container-color);
  text-align: center;
  font-size: var(--smaller-font-size);
}


/*=============== SCROLL BAR ===============*/
::-webkit-scrollbar{
  width: .6rem;
  background-color: hsl(var(--second-hue), 8%, 38%);
  border-radius: .5rem;
}
::-webkit-scrollbar-thumb{
  background-color: hsl(var(--second-hue), 8%, 26%);
  border-radius: .5rem;
}
::-webkit-scrollbar-thumb:hover{
  background-color: hsl(var(--second-hue), 8%, 20%);
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 320px){
  .nav__menu{
    padding: 1rem 1.5rem;
  }
  .home__buttons{
    flex-direction: column;
  }
  .home__handle{
    width: 150px;
    height: 253px;
  }
  .home__img{
    width: 130px;
  }
  .about__info{
    grid-template-columns: repeat(2,1fr);
  }
  .skills__box{
    column-gap: 1rem;
  }
  .skills__name{
    font-size: var(--small-font-size);
  }
  .services__container{
    grid-template-columns:145px ;
    justify-content: center;
  }
  .work__item{
    font-size: var(--small-font-size);
  }
  .work__filters{
    column-gap: .25rem;
  }
  
}

/* For medium devices */
@media screen and (min-width:576px){
  .nav__menu{
    width: 328px;
    right: 0;
    left: 0;
    margin:  0 auto;
  }
  .about__info{
    grid-template-columns: repeat(3,140px);
    justify-content: center;
  }
  .about__description{
    padding: 0 5rem;
  }
  .skills__container{
   justify-content: center; 
  }
  .skills__content{
    padding: 2rem 4rem;
  }
  .services__container{
    grid-template-columns: repeat(2, 160px);
    justify-content: center;
  }
  .services__modal-content{
    width: 500px;
    padding: 4.5rem 2.5rem 2.5rem;
  }
  .services__modal-description{
    padding: 0 3rem;
  }
  .work__container{
    justify-content: center;
  }
  .work__img{
    width: 295px;
    max-width: 100%;
  }
  .contact__info{
    grid-template-columns: 300px;
    justify-content: center;
  }
  .contact__form{
    width: 360px;
    margin: 0 auto;
  }
}
@media screen and (min-width:767px){
  .work__container{
   grid-template-columns: repeat(2,max-content); 
  }
  .contact__container{
    grid-template-columns: repeat(2 ,max-content);
    justify-content: center;
    column-gap: 3rem;
  }
  
}

/* For large devices */
@media screen and (min-width: 992px){
  .container{
    margin-left: auto;
    margin-right: auto;
  }
  .section{
    padding:6.5rem 0 1rem ;
  }
  .section__title{
    margin-bottom: 3.5rem;
  }
  .nav{
    height: calc(var(--header-height) + 1rem);
  }
  .home__handle{
    width: 290px;
    height: 400px;
  }

  .home__img{
    width: 380px !important;
  }
  .home__social-link{
    padding: .4rem;
    font-size: 1.25rem;
  }
  .home__social::after{
    transform: rotate(90deg), translate(16px,0);
  }
  .home__scroll-icon{
    font-size: 2rem;
  }
  .about__container{
    grid-template-columns: repeat(2,1fr);
    align-items: center;
    column-gap: 4rem;
  }
  .about__img{
    width: 350px;
  }
  .about__data{
    text-align: initial;
  }
  .about__info{
    justify-content: initial;
  }
  .about__box{
    text-align: center;
    padding: 1rem 1.25rem;
  }
  .about__description{
    padding: 0 4rem 0  0;
    margin-bottom: 2rem;
  }
  .skills__container{
    grid-template-columns: repeat(2,350px);
  }
  .services__container{
    grid-template-columns: repeat(3,192px);
    column-gap: 3rem;
  }
  .services__card{
    padding: 2rem 1rem 0.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .work__container{
    gap: 3rem;
  }
  .work__card{
    padding: 1.25rem;
  }
  .work__img{
    margin-bottom: 1rem;
    max-width: 100%;
  }
  .work__title{
   margin-bottom: .5rem; 
  }
  .testimonial__container{
    width: 750px;
  }
  .testimonial__card{
    padding: 1.5rem 2rem;
  }
  .contact__container{
    column-gap: 6rem;
  }
  .footer__social-link{
    font-size:1.25rem ;
    padding: .4rem;
    border-radius: .5rem;
  }
  
}